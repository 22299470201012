export const eventList = [
    {
        title: 'Premiera książki „Zrolowany wrześniowy Vogue”',
        date: {
            start: new Date(2020, 4, 22, 19, 0),
            end: new Date(2020, 4, 22, 21, 15),
        },
        organiser: 'Spółdzielnia Ogniwo',
        description: 'Prowadzenie Tomasz Charnas',
        urls: [
            {
                type: 'facebook',
                url: 'https://www.facebook.com/ogniwokrakow/videos/665702967311312/',
            },
        ],
    },
    {
        title: 'Premiera książki „Lakier”',
        date: {
            start: new Date(2022, 10, 28, 18, 0),
            end: new Date(2022, 10, 28, 19, 0),
        },
        organiser: 'Wydawnictwo Granda',
        description: 'Rozmowę poprowadzi Mich Pabian',
        urls: [
            {
                type: 'facebook',
                url: 'https://www.facebook.com/events/1466023520585790/',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/watch?v=hgOhEqrDlJY',
            },
        ],
    },
    {
        title: 'Spotkanie autorskie',
        date: {
            start: new Date(2023, 0, 11, 19, 0),
            end: new Date(2023, 0, 11, 20, 0),
        },
        organiser: 'Kanał Książkowy',
        description: 'Prowadzi Gabriel Augustyn',
        urls: [
            {
                type: 'facebook',
                url: 'https://www.facebook.com/events/3094736734159723/',
            },
            {
                type: 'youtube',
                url: 'https://www.youtube.com/watch?v=dQO1ZiJ1lEE',
            },
        ],
    },
    {
        title: 'Podcast „Zmierzch familijnych tyranów”',
        date: {
            start: new Date(2023, 1, 16, 15, 0),
            end: new Date(2023, 1, 16, 16, 0),
        },
        organiser: 'Nowy Gramofon',
        description: 'Rozmowa z Patrykiem Kosendą',
        urls: [
            {
                type: 'spotify',
                url: 'https://open.spotify.com/episode/4LBOjCZj2lJbDrSIIqPGCc',
            },
        ],
    },
    {
        title: 'Reportaż „Goście Radziwiłłów 2023”',
        date: {
            start: new Date(2023, 8, 15, 12, 0),
            end: new Date(2023, 8, 15, 13, 0),
        },
        organiser: 'Kultura u Podstaw',
        description: 'Materiał audio z udziałem uczestników rezydencji literackich „Goście Radziwiłłów” w Antoninie',
        urls: [
            {
                type: '',
                url: 'https://kulturaupodstaw.pl/audio/91750/',
            },
        ],
    },
    {
        title: 'Festiwal Czytania',
        date: {
            start: new Date(2023, 10, 25, 16, 0),
            end: new Date(2023, 10, 25, 17, 0),
        },
        organiser: 'Książnica Pomorska',
        description: 'Spotkanie z autorami książek nominowanych do Nagrody Literackiej „Jantar”. Prowadzi Konrad Wojtyła',
        urls: [],
    },
    {
        title: 'Festiwal Literatura Działa',
        date: {
            start: new Date(2024, 7, 11, 15, 0),
            end: new Date(2024, 7, 11, 16, 0),
        },
        organiser: 'Literatura Działa',
        description: 'Prowadzi Krzysztof Lichtblau',
        urls: [],
    },
    {
        title: 'Podcast OFF Radia',
        date: {
            start: new Date(2024, 7, 21, 15, 0),
            end: new Date(2024, 7, 21, 16, 0),
        },
        organiser: 'OFF Radio Kraków',
        description: 'Rozmowa z Mateuszem Demskim',
        urls: [],
    },
    {
        title: 'Dobry Tytuł',
        date: {
            start: new Date(2024, 10, 2, 17, 30),
            end: new Date(2024, 10, 2, 18, 0),
        },
        organiser: 'TVP2',
        description: 'Rozmowa z Agatą Passent i Szymonem Kloską w programie czytelniczo-publicystycznym TVP2',
        urls: [
            {
                type: 'video',
                url: 'https://vod.tvp.pl/programy,88/dobry-tytul-odcinki,1536392/odcinek-9,S01E09,1690302',
            }
        ],
    },
    {
        title: 'Jak pisać autofikcję',
        date: {
            start: new Date(2024, 9, 26, 11, 0),
            end: new Date(2024, 10, 9, 14, 0),
        },
        organiser: 'Dom Sztuki',
        description: 'Warsztaty z pisania autofikcji',
        urls: [
            {
                type: '',
                url: 'https://domsztuki.org/jak-pisac-autofikcje-1/',
            },
            {
                type: 'instagram',
                url: 'https://www.instagram.com/p/DCMNnfnKzgq/',
            }
        ],
    },
    {
        title: 'Czy nierozumienie nie jest też formą poznawania?',
        date: {
            start: new Date(2024, 10, 3, 19, 0),
            end: new Date(2024, 10, 3, 20, 0),
        },
        organiser: 'wydawnictwo j',
        description: 'Mirka Szychowiak pyta o „W raju się nie zgubisz”',
        urls: [
            {
                type: '',
                url: 'http://wydawnictwoj.pl/uncategorized/pismo/rozmowy/czy-nierozumienie-nie-jest-tez-forma-poznawania-z-jarkiem-skurzyskim-o-w-raju-sie-nie-zgubisz-rozmawia-mirka-szychowiak/',
            }
        ],
    },
    {
        title: 'Spotkanie autorskie',
        date: {
            start: new Date(2024, 10, 9, 18, 0),
            end: new Date(2024, 10, 9, 20, 0),
        },
        organiser: 'Najlepsza Księgarnia',
        description: 'Rozmowa o „Złym synu”',
        urls: [
            {
                type: 'facebook',
                url: 'https://www.facebook.com/watch/?v=465708835999120',
            }
        ],
    },
    {
        title: 'Słowa suną za osobami',
        date: {
            start: new Date(2025, 0, 7, 19, 0),
            end: new Date(2024, 0, 7, 20, 0),
        },
        organiser: 'wydawnictwo j',
        description: 'Rozmowa z Katarzyną Michalczak o „W raju się nie zgubisz”',
        urls: [
            {
                type: '',
                url: 'https://wydawnictwoj.pl/uncategorized/pismo/rozmowy/slowa-suna-za-osobami-rozmowa-katarzyny-michalczak-z-jarkiem-skurzynskim-o-w-raju-sie-nie-zgubisz/',
            }
        ],
    },
    {
        title: 'Hala Odlotów',
        date: {
            start: new Date(2025, 2, 13, 20, 0),
            end: new Date(2025, 2, 13, 121, 0),
        },
        organiser: 'TVP Kultura',
        description: '„Toksyczni” rodzice i konflikt pokoleń',
        urls: [
            {
                type: 'video',
                url: 'https://vod.tvp.pl/programy,88/hala-odlotow-odcinki,273784/odcinek-168,S02E168,1964015',
            }
        ],
    },
];
